import {
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

import {
  IconButton,
  LoadingIndicator,
  useToast,
} from "../../../../../components";
import { Button } from "../../../../../components/Buttons";
import AILoading from "../../../../../components/LoadingIndicator/AILoading";
import {
  SkillReportSkillsSource,
  useAnalyticsSkillDescriptionLazyQuery,
  usePositionJobDescriptionSkillsLazyQuery,
} from "../../../../graphql";
import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";
import { StepWrapper } from "./SkillsReportBuilderStepOne";
import { PositionOptions } from "./SkillsReportPositionSelect";
import { doesPositionHaveJobPosting } from "./utils";

type StepTwoSkill = {
  id: string;
  name: string;
  description: string;
  aiLoading?: boolean;
};

type FormPositionOption = {
  value: string;
  label: string;
};

export interface SkillsReportBuilderStepTwoFormData {
  source: SkillReportSkillsSource;
  importPosition?: FormPositionOption | null;
  skills: StepTwoSkill[];
}

type StepTwoProps = {
  selectedPositions: PositionOptions;
  onComplete: (data: SkillsReportBuilderStepTwoFormData) => void;
  onCancel: () => void;
};

const SkillsReportBuilderStepTwo: React.FC<StepTwoProps> = ({
  selectedPositions,
  onComplete,
  onCancel,
}) => {
  const toast = useToast();
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const canImportSkills = selectedPositions.some(doesPositionHaveJobPosting);

  const defaultValues = {
    source: SkillReportSkillsSource.User,
    importPosition: null,
    skills: [{ id: uuidv4(), name: "", description: "", aiLoading: false }],
  };
  const { control, register, handleSubmit, setValue, watch } =
    useForm<SkillsReportBuilderStepTwoFormData>({
      defaultValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const skills = watch("skills");
  const hasAtLeastOneNonEmpty = skills.some((item) => item.name.trim() !== "");

  const onSubmit = (formData: SkillsReportBuilderStepTwoFormData): void => {
    const { source, skills, importPosition } = formData;
    onComplete({ source, skills, importPosition });
  };

  const source = watch("source");
  const isJdSource = source === SkillReportSkillsSource.AtsJobDescription;
  const sourceOptions: {
    value: SkillReportSkillsSource;
    label: string;
    isDisabled?: boolean;
  }[] = [
    {
      value: SkillReportSkillsSource.User,
      label: "Create my own skill list (w/ AI assistant)",
    },
    {
      value: SkillReportSkillsSource.AtsJobDescription,
      label: "Generate from ATS job description",
      isDisabled: !canImportSkills,
    },
  ];

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 340,
      ...containerStyles,
    }),
    ...selectStylesRest,
  };

  const [fetchSkillDescription] = useAnalyticsSkillDescriptionLazyQuery();

  const [fetchPositionJobDescriptionSkills, { loading: skillsLoading }] =
    usePositionJobDescriptionSkillsLazyQuery();

  const onImportSkillsChange = (option?: FormPositionOption | null): void => {
    const positionId = option?.value;
    if (!positionId) {
      return;
    }
    fetchPositionJobDescriptionSkills({
      variables: { positionId },
      onCompleted: (data) => {
        const skills = data.positionJobDescriptionSkills?.skills;
        if (skills) {
          setValue(
            "skills",
            skills.map((skill, i) => ({
              id: uuidv4(),
              name: skill.name,
              description: skill.description || "",
            }))
          );
        }
        if (!skills || skills.length === 0) {
          toast({
            title: "No skills found",
            description: "We couldn't find any skills in the job description.",
            status: "info",
          });
        }
      },
      onError: () => {
        toast({
          title: "Error fetching skills",
          description:
            "Unable to retrieve skills from the job description. Please try again later.",
          status: "error",
        });
      },
    });
  };

  const importPositionOptions = selectedPositions
    .filter(doesPositionHaveJobPosting)
    .map((position) => ({
      value: position.id,
      label: position.displayTitle,
    }));

  return (
    <StepWrapper>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="2xl" color="gray.900" fontWeight="600">
          Report builder
        </Text>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          disabled={fields.length === 0 || !hasAtLeastOneNonEmpty}
          onClick={handleSubmit(onSubmit)}
        >
          Run report
        </Button>
      </Flex>
      <Divider my="6" borderColor="gray.200" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl maxWidth="300px">
          <FormLabel color="gray.600" mb="1">
            Skills source
          </FormLabel>
          <Select
            {...register("source")}
            styles={commonSelectStyles}
            theme={selectTheme}
            options={sourceOptions}
            defaultValue={sourceOptions[0]}
            onChange={(option) => {
              if (!option) {
                return;
              }
              setValue("source", option.value);
            }}
          />
        </FormControl>
        {isJdSource && (
          <FormControl mt="6" maxWidth="300px">
            <FormLabel color="gray.600" mb="1">
              Job description
            </FormLabel>
            <Controller
              name="importPosition"
              control={control}
              rules={{ required: isJdSource }}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={commonSelectStyles}
                  theme={selectTheme}
                  options={importPositionOptions}
                  placeholder="Select job description"
                  onChange={(option) => {
                    field.onChange(option);
                    onImportSkillsChange(option);
                  }}
                />
              )}
            />
          </FormControl>
        )}
        <Text mt="5" mb="2" color="gray.600" fontWeight="400" fontSize="sm">
          Skills
        </Text>
        <VStack
          spacing="4"
          alignItems="flex-start"
          backgroundColor="gray.50"
          py="5"
          px="4"
          borderRadius="12px"
          mb="8"
        >
          {skillsLoading && (
            <Center w="100%">
              <AILoading />
            </Center>
          )}
          {!skillsLoading &&
            fields.map((field, index) => (
              <Flex
                flexDir="row"
                alignItems="flex-start"
                justifyItems="flex-start"
                width="100%"
                key={field.id}
              >
                <Input
                  width="280px"
                  mr="2"
                  placeholder="Skill"
                  required
                  _placeholder={{ color: "gray.600" }}
                  {...register(`skills.${index}.name`, {
                    maxLength: 100,
                    onBlur: (e) => {
                      const skillName = e.target.value.trim();
                      const skillDescription = skills[index].description.trim();
                      if (
                        skillName &&
                        skillName !== "" &&
                        skillDescription === ""
                      ) {
                        setValue(`skills.${index}.aiLoading`, true);
                        fetchSkillDescription({
                          variables: {
                            skillName,
                          },
                          onCompleted: (data) => {
                            const description =
                              data.skillDescription?.description;
                            setValue(
                              `skills.${index}.description`,
                              description || ""
                            );
                            setValue(`skills.${index}.aiLoading`, false);
                          },
                          onError: () => {
                            setValue(`skills.${index}.aiLoading`, false);
                            toast({
                              title: "Error",
                              description:
                                "Failed to fetch description. Please try again.",
                              status: "error",
                            });
                          },
                        });
                      }
                    },
                  })}
                />
                <Flex mr="2" flexGrow="1">
                  <InputGroup>
                    {skills[index].aiLoading && (
                      <InputLeftElement>
                        <LoadingIndicator size="sm" color="purple.500" />
                      </InputLeftElement>
                    )}
                    <Input
                      placeholder={
                        skills[index].aiLoading
                          ? ""
                          : "Optional description or examples"
                      }
                      _placeholder={{ color: "gray.400" }}
                      isDisabled={skills[index].aiLoading}
                      {...register(`skills.${index}.description`, {
                        maxLength: 500,
                      })}
                    />
                  </InputGroup>
                </Flex>
                <IconButton
                  aria-label="remove skill"
                  type="button"
                  variant="icon"
                  backgroundColor="transparent"
                  size="sm"
                  icon={<Icon as={HiOutlineTrash} boxSize="5" />}
                  onClick={() => remove(index)}
                />
              </Flex>
            ))}
          {!skillsLoading && (
            <Button
              aria-label="add skill"
              variant="ghost"
              fontWeight="500"
              size="sm"
              leftIcon={<Icon as={HiOutlinePlus} boxSize="5" />}
              onClick={() => {
                append({
                  id: uuidv4(),
                  name: "",
                  description: "",
                });
              }}
            >
              Add skill
            </Button>
          )}
        </VStack>
      </form>
      <Divider borderColor="gray.200" />
      <Flex flexDir="row" justifyContent="space-between" mt="6">
        <Button variant="ghost" fontWeight="500" size="sm" onClick={onCancel}>
          Back
        </Button>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          onClick={handleSubmit(onSubmit)}
          disabled={fields.length === 0 || !hasAtLeastOneNonEmpty}
        >
          Run report
        </Button>
      </Flex>
    </StepWrapper>
  );
};

export default SkillsReportBuilderStepTwo;

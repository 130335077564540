import {
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "../../../../components";
import { formatDate } from "../../../../utils/datetime";
import {
  SkillReport,
  SkillReportSkillsSource,
  useRunSkillReportMutation,
} from "../../../graphql";
import { ThGray } from "../AnalyticsReportTable";

type SkillReportListItem = Pick<
  SkillReport,
  "id" | "title" | "source" | "createdAt" | "dateRangeStart" | "dateRangeEnd"
> & {
  skills: {
    name: string;
    description?: string | null;
  }[];
  positions: {
    id: string;
    displayTitle: string;
  }[];
};

const TABLE_CELL_PROPS: TableCellProps = {
  px: 2,
  minW: "100px",
  textAlign: "left",
};

const TABLE_HEADER_CELL_PROPS = {
  ...TABLE_CELL_PROPS,
  fontSize: "xs",
};
const TABLE_DATA_CELL_PROPS = {
  ...TABLE_CELL_PROPS,
  fontSize: "sm",
};

type SkillsReportTableProps = {
  reports: SkillReportListItem[];
};

const SkillsReportTable: React.FC<SkillsReportTableProps> = ({ reports }) => {
  // TODO: Add report name and report source to table when available
  // TODO: Make the table sortable
  const [runSkillReport] = useRunSkillReportMutation();
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Report</ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Date created </ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Date range</ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS} textAlign="right">
              Positions
            </ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Skills source</ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS} textAlign="right">
              Skills count
            </ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS} textAlign="right">
              Run report
            </ThGray>
          </Tr>
        </Thead>
        <Tbody>
          {reports.map((report: SkillReportListItem) => (
            <Tr
              key={report.id}
              _hover={{ backgroundColor: "blue.50", cursor: "pointer" }}
              onClick={() => navigate(`/insights/skill-report/${report.id}`)}
            >
              <Td {...TABLE_DATA_CELL_PROPS}>{report.title}</Td>
              <Td {...TABLE_DATA_CELL_PROPS}>{formatDate(report.createdAt)}</Td>
              <Td {...TABLE_DATA_CELL_PROPS}>
                {formatDate(report.dateRangeStart, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}{" "}
                -{" "}
                {formatDate(report.dateRangeEnd, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </Td>
              <Td {...TABLE_DATA_CELL_PROPS} textAlign="right">
                {report.positions.length}
              </Td>
              <Td {...TABLE_DATA_CELL_PROPS}>
                {report.source === SkillReportSkillsSource.AtsJobDescription
                  ? "Job description"
                  : "Custom"}
              </Td>
              <Td {...TABLE_DATA_CELL_PROPS} textAlign="right">
                {report.skills.length}
              </Td>
              <Td {...TABLE_DATA_CELL_PROPS} textAlign="right">
                <Link
                  onClick={() => {
                    runSkillReport({
                      variables: {
                        reportId: report.id,
                        regenerate: true,
                      },
                      onCompleted: (data) => {
                        const newReportId = data.runSkillReport?.skillReport.id;
                        if (newReportId) {
                          navigate(`/insights/skill-report/${newReportId}`);
                        }
                      },
                    });
                  }}
                >
                  Re-run report
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SkillsReportTable;

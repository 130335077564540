import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  IconButton,
  LoadingIndicator,
} from "../../../../../components";
import AILoading from "../../../../../components/LoadingIndicator/AILoading";
import {
  SkillReportSkillsSource,
  useAnalyticsSkillDescriptionLazyQuery,
  usePositionJobDescriptionSkillsLazyQuery,
} from "../../../../graphql";
import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";
import { FormPositionOption, SkillsBuilderFormControlsData } from "./types";
import { UseSkillsReportBuilderReturn } from "./useSkillsReportBuilder";
import { doesPositionHaveJobPosting } from "./utils";

type SkillsBuilderFormControlsProps = Pick<
  UseSkillsReportBuilderReturn,
  "selectedPositions" | "source" | "setSource" | "skills" | "setSkills"
>;

const SkillsBuilderFormControls: React.FC<SkillsBuilderFormControlsProps> = ({
  selectedPositions,
  skills,
  setSkills,
  source,
  setSource,
}) => {
  const toast = useToast();

  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();

  const [fetchSkillDescription] = useAnalyticsSkillDescriptionLazyQuery();

  const [fetchPositionJobDescriptionSkills, { loading: skillsLoading }] =
    usePositionJobDescriptionSkillsLazyQuery();

  const canImportSkills = selectedPositions.some(doesPositionHaveJobPosting);

  const defaultValues = {
    source: source || SkillReportSkillsSource.User,
    importPosition: null,
    skills: skills.length
      ? skills.map((skill) => ({ ...skill, aiLoading: false }))
      : [{ id: uuidv4(), name: "", description: "", aiLoading: false }],
  };
  const { control, register, setValue, watch } =
    useForm<SkillsBuilderFormControlsData>({
      defaultValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const formValues = watch();

  // We don't have a dedicated submit button, so we submit the skill values on every change
  useEffect(() => {
    setSource(formValues.source);
    setSkills(formValues.skills);
  }, [formValues, setSkills]);

  const isJdSource =
    formValues.source === SkillReportSkillsSource.AtsJobDescription;
  const sourceOptions: {
    value: SkillReportSkillsSource;
    label: string;
    isDisabled?: boolean;
  }[] = [
    {
      value: SkillReportSkillsSource.User,
      label: "Create my own skill list (w/ AI assistant)",
    },
    {
      value: SkillReportSkillsSource.AtsJobDescription,
      label: "Generate from ATS job description",
      isDisabled: !canImportSkills,
    },
  ];

  const onImportSkillsChange = (option?: FormPositionOption | null): void => {
    const positionId = option?.value;
    if (!positionId) {
      return;
    }
    fetchPositionJobDescriptionSkills({
      variables: { positionId },
      onCompleted: (data) => {
        const skills = data.positionJobDescriptionSkills?.skills;
        if (skills) {
          setValue(
            "skills",
            skills.map((skill, i) => ({
              id: uuidv4(),
              name: skill.name,
              description: skill.description || "",
            }))
          );
        }
        if (!skills || skills.length === 0) {
          toast({
            title: "No skills found",
            description: "We couldn't find any skills in the job description.",
            status: "info",
          });
        }
      },
      onError: () => {
        toast({
          title: "Error fetching skills",
          description:
            "Unable to retrieve skills from the job description. Please try again later.",
          status: "error",
        });
      },
    });
  };

  const importPositionOptions = useMemo(() => {
    return selectedPositions
      .filter(doesPositionHaveJobPosting)
      .map((position) => ({
        value: position.id,
        label: position.displayTitle,
      }));
  }, [selectedPositions]);

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = useMemo(() => {
    return {
      container: (provided: Record<string, any>) => ({
        ...provided,
        flex: 1,
        minWidth: 340,
        ...containerStyles,
      }),
      ...selectStylesRest,
    };
  }, []);

  return (
    <>
      <Flex alignItems="center">
        <FormControl width="350px">
          <FormLabel color="gray.600" mb="1">
            Skills source
          </FormLabel>
          <Controller
            name="source"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                styles={commonSelectStyles}
                theme={selectTheme}
                options={sourceOptions}
                value={sourceOptions.find((o) => o.value === source)}
                onChange={(option) => {
                  if (!option) {
                    return;
                  }
                  setValue("source", option.value);
                }}
              />
            )}
          />
        </FormControl>
        {isJdSource && (
          <FormControl width="350px" ml="4">
            <FormLabel color="gray.600" mb="1">
              Job description
            </FormLabel>
            <Controller
              name="importPosition"
              control={control}
              rules={{ required: isJdSource }}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={commonSelectStyles}
                  theme={selectTheme}
                  options={importPositionOptions}
                  placeholder="Select job description"
                  onChange={(option) => {
                    field.onChange(option);
                    onImportSkillsChange(option);
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </Flex>
      <Text mt="5" mb="2" color="gray.600" fontWeight="400" fontSize="sm">
        Skills
      </Text>
      <VStack
        spacing="4"
        alignItems="flex-start"
        backgroundColor="gray.50"
        py="5"
        px="4"
        borderRadius="12px"
        mb="8"
      >
        {skillsLoading && (
          <Center w="100%">
            <AILoading />
          </Center>
        )}
        {!skillsLoading &&
          fields.map((field, index) => (
            <Flex
              flexDir="row"
              alignItems="flex-start"
              justifyItems="flex-start"
              width="100%"
              key={field.id}
            >
              <Input
                width="280px"
                mr="2"
                placeholder="Skill"
                required
                _placeholder={{ color: "gray.600" }}
                {...register(`skills.${index}.name`, {
                  maxLength: 100,
                  onBlur: (e) => {
                    const skillName = e.target.value.trim();
                    const skillDescription =
                      formValues.skills[index].description?.trim();
                    if (
                      skillName &&
                      skillName !== "" &&
                      skillDescription === ""
                    ) {
                      setValue(`skills.${index}.aiLoading`, true);
                      fetchSkillDescription({
                        variables: {
                          skillName,
                        },
                        onCompleted: (data) => {
                          const description =
                            data.skillDescription?.description;
                          setValue(
                            `skills.${index}.description`,
                            description || ""
                          );
                          setValue(`skills.${index}.aiLoading`, false);
                        },
                        onError: () => {
                          setValue(`skills.${index}.aiLoading`, false);
                          toast({
                            title: "Error",
                            description:
                              "Failed to fetch description. Please try again.",
                            status: "error",
                          });
                        },
                      });
                    }
                  },
                })}
              />
              <Flex mr="2" flexGrow="1">
                <InputGroup>
                  {formValues.skills[index].aiLoading && (
                    <InputLeftElement>
                      <LoadingIndicator size="sm" color="purple.500" />
                    </InputLeftElement>
                  )}
                  <Input
                    placeholder={
                      formValues.skills[index].aiLoading
                        ? ""
                        : "Optional description or examples"
                    }
                    _placeholder={{ color: "gray.400" }}
                    isDisabled={formValues.skills[index].aiLoading}
                    {...register(`skills.${index}.description`, {
                      maxLength: 500,
                    })}
                  />
                </InputGroup>
              </Flex>
              <IconButton
                aria-label="remove skill"
                type="button"
                variant="icon"
                backgroundColor="transparent"
                size="sm"
                icon={<Icon as={HiOutlineTrash} boxSize="5" />}
                onClick={() => remove(index)}
              />
            </Flex>
          ))}
        {!skillsLoading && (
          <Button
            aria-label="add skill"
            variant="ghost"
            fontWeight="500"
            size="sm"
            leftIcon={<Icon as={HiOutlinePlus} boxSize="5" />}
            onClick={() => {
              append({
                id: uuidv4(),
                name: "",
                description: "",
                aiLoading: false,
              });
            }}
          >
            Add skill
          </Button>
        )}
      </VStack>
    </>
  );
};

export default SkillsBuilderFormControls;

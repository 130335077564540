import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Button } from "../../../../components";

const EditTemplateSaveAsModal: React.FC<{
  currentTitle: string;
  onClose: () => void;
  handleSave: (asNewTemplate: boolean, newTitle: string) => void;
}> = ({ currentTitle, onClose, handleSave }) => {
  const [newTitle, setNewTitle] = useState(currentTitle);
  const [isError, setIsError] = useState(false);

  const handleSaveAs = (): void => {
    const newTitleTrimmed = newTitle.trim();
    if (newTitleTrimmed === "") {
      setIsError(true);
    } else {
      handleSave(true, newTitleTrimmed);
    }
  };

  return (
    <Modal size="lg" isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Save New Candidate Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3} isInvalid={isError}>
              <FormLabel htmlFor="newTemplateTitle">Save As</FormLabel>
              <Input
                id="newTemplateTitle"
                name="newTemplateTitle"
                value={newTitle}
                onChange={(event) => {
                  if (isError) {
                    setIsError(false);
                  }
                  setNewTitle(event.target.value);
                }}
                maxLength={100}
              />
              {isError && (
                <FormErrorMessage>Input cannot be empty.</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" size="sm" onClick={onClose}>
              Cancel
            </Button>
            <Button ml={4} size="sm" onClick={() => handleSaveAs()}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EditTemplateSaveAsModal;

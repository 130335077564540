import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback } from "react";

import { Button } from "../../../../components";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  SkillReportDocument,
  SkillReportQuery,
  SkillsReportWithResultsFragment,
  useEditSkillReportMutation,
  useRunSkillReportMutation,
} from "../../../graphql";
import ReportBuilderFormControls from "./builder/ReportBuilderFormControls";
import SkillsBuilderFormControls from "./builder/SkillsBuilderFormControls";
import useSkillsReportBuilder from "./builder/useSkillsReportBuilder";

interface EditSkillsReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: SkillsReportWithResultsFragment;
}

const EditSkillsReportModal: React.FC<EditSkillsReportModalProps> = ({
  isOpen,
  onClose,
  report,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const {
    selectedPositions,
    setSelectedPositions,
    candidateFilter,
    setCandidateFilter,
    interviewStages,
    setInterviewStages,
    minInterviewCount,
    setMinInterviewCount,
    dateRange,
    setDateRange,
    title,
    setTitle,
    skills,
    setSkills,
    source,
    setSource,
  } = useSkillsReportBuilder(report);

  const [runSkillsReport] = useRunSkillReportMutation();

  const [editSkillReport] = useEditSkillReportMutation({
    update(cache, { data }) {
      if (data?.editSkillReport?.skillReport) {
        cache.writeQuery<SkillReportQuery>({
          query: SkillReportDocument,
          data: {
            skillReport: data.editSkillReport.skillReport,
          },
        });
      }
    },
    onError: () =>
      toast({ status: "error", title: "Error editing skills report" }),
    onCompleted: (data) => {
      const reportId = data.editSkillReport?.skillReport.id;
      if (reportId) {
        sendGAEvent("edit_skill_report", "analytics", reportId);
        const resultsCleared =
          data.editSkillReport?.skillReport.results.length === 0;
        if (resultsCleared) {
          runSkillsReport({
            variables: {
              reportId,
            },
          });
        }
        const toastMessage = `Skills report updated! ${
          resultsCleared ? "Regenerating results..." : ""
        }`;
        toast({
          title: toastMessage,
          status: "success",
          duration: 3000,
          isClosable: false,
          position: "top",
        });
        onClose();
      }
    },
  });

  const applyChanges = useCallback(() => {
    const interviewStageFilters = JSON.stringify(
      interviewStages.map((stage) => ({
        label: stage.label,
        value: stage.value,
      }))
    );
    editSkillReport({
      variables: {
        reportId: report?.id,
        title,
        dateRangeStart: formatISODate(dateRange.start),
        dateRangeEnd: formatISODate(dateRange.end),
        positionIds: selectedPositions.map((position) => position.id),
        interviewStageFilters,
        minimumInterviewCountFilter: minInterviewCount,
        source,
        skills: skills.map((skill) => ({
          name: skill.name,
          description: skill.description,
        })),
      },
    });
  }, [
    editSkillReport,
    report?.id,
    title,
    dateRange,
    selectedPositions,
    interviewStages,
    minInterviewCount,
    source,
    skills,
  ]);

  if (!report) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent borderRadius="24px" maxWidth="960px">
          <ModalHeader
            color="gray.900"
            fontWeight="600"
            fontSize="2xl"
            lineHeight="8"
            pl="6"
            pr="72px"
            pt="6"
            pb="4"
            borderBottomColor="gray.100"
          >
            Edit report
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px="10" pt="8" pb="14">
            <form onSubmit={applyChanges}>
              <Box mb="6">
                <Text mb="4" color="gray.900" fontWeight="600" fontSize="md">
                  Title & Filters
                </Text>
                <ReportBuilderFormControls
                  selectedPositions={selectedPositions}
                  setSelectedPositions={setSelectedPositions}
                  candidateFilter={candidateFilter}
                  setCandidateFilter={setCandidateFilter}
                  interviewStages={interviewStages}
                  setInterviewStages={setInterviewStages}
                  minInterviewCount={minInterviewCount}
                  setMinInterviewCount={setMinInterviewCount}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  title={title}
                  setTitle={setTitle}
                />
              </Box>
              <Text mb="4" color="gray.900" fontWeight="600" fontSize="md">
                Skills
              </Text>
              <SkillsBuilderFormControls
                selectedPositions={selectedPositions}
                skills={skills}
                setSkills={setSkills}
                source={source}
                setSource={setSource}
              />
            </form>
          </ModalBody>
          <ModalFooter
            borderTop="1px"
            borderTopColor="gray.100"
            justifyContent="space-between"
            px={6}
          >
            <Button variant="ghost" fontWeight="500" onClick={onClose}>
              Cancel
            </Button>
            <Button
              fontWeight="500"
              textTransform="unset"
              borderRadius="8px"
              onClick={applyChanges}
            >
              Apply changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EditSkillsReportModal;

import { Center } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { Alert, LoadingIndicator } from "../../../../components";
import { SkillReportProcessingStatus } from "../../../graphql";
import AnalyticsSkillsReportResults from "./AnalyticsSkillsReportResults";
import useSkillsReport from "./useSkillsReport";

const AnalyticsSkillsReport: React.FC = () => {
  // The router handles the case where the reportId is not present
  const { reportId } = useParams() as { reportId: string };

  const { loading, skillReport, statusLoading, status, error } =
    useSkillsReport(reportId);

  if (loading || statusLoading) {
    return (
      <Center width="100%">
        <LoadingIndicator />
      </Center>
    );
  }

  if (error) {
    return <Alert status="error" description="Error loading report" reload />;
  }

  if (!skillReport) {
    return <Alert status="error" description="Report not found" reload />;
  }

  return (
    <AnalyticsSkillsReportResults
      skillReport={skillReport}
      generating={
        !!status &&
        [
          SkillReportProcessingStatus.Dispatched,
          SkillReportProcessingStatus.InProgress,
        ].includes(status)
      }
    />
  );
};

export default AnalyticsSkillsReport;

import { ReportPositionOptionFragment } from "../../../../graphql";

export const doesPositionHaveJobPosting = (
  position: ReportPositionOptionFragment
): boolean => {
  const jobPostingIds = [
    position.greenhouseId,
    position.leverPostingId,
    position.smartrecruitersId,
    position.mergeId,
  ];
  return jobPostingIds.some((id) => !!id);
};

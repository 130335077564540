import invariant from "invariant";

import { iconForNote } from "../main/components/CallNotes/utils";
import {
  AiQuestionNoteAnswerItem,
  Ats,
  CallAiSummaryFormat,
  CallNoteType,
  LeverFeedbackTemplateFieldTypes,
  NotePartsFragment,
  NotesForScorecardQuery,
  NotesForScorecardV3Query,
} from "../main/graphql";
import { buildCallTimestampLink, buildNoteTimestampLink } from "./call";
import { formatDuration } from "./datetime";

export const isSupportedAts = (ats: Ats): boolean =>
  [
    Ats.Greenhouse,
    Ats.Lever,
    Ats.Ashby,
    Ats.MergeWorkday,
    Ats.Smartrecruiters,
  ].includes(ats);

/** Used to differentiate `NotesForScorecardQuery` from `NotesForScorecardV3Query` */
const isV3Call = (call: any): call is NotesForScorecardV3Query["call"] =>
  !!call?.scorecard;

interface CueNotes {
  description?: string;
  cues: string[];
  notes: string[];
}

interface ExtensionNote {
  /**
   * The total number of notes for the section
   */
  count: number;
  /**
   * All notes as a string, to be pasted into a scorecard section
   */
  text: string;
  /**
   * All notes as a string, to be pasted in the general notes
   * section when the note cannot be matched to a scorecard section
   */
  generalText: string;
}

interface QuestionNotes {
  id: string;
  text: string;
  notes: ExtensionNote;
}

export interface AlertConfig {
  type: "success" | "info";
  title: string;
  body?: string;
  action?: {
    actionName: string;
    displayText: string;
  };
  afterAction?: {
    title: string;
    body: string;
  };
  openDelay?: number;
  duration?: number;
}

export interface FillScorecardParams {
  call: NotesForScorecardQuery["call"] | NotesForScorecardV3Query["call"];
  ats: Ats;
  autofill: boolean;
  showConfetti: boolean;
  /**
   * Code behind `!latestFormatEnabled` should be considered brownfield and
   * should not need to be maintained.
   */
  latestFormatEnabled: boolean;
  hideAiHeaders?: boolean;
  includeTimestamps?: boolean;
}

export interface FillScorecardPayload {
  generalNotes: ExtensionNote;
  questionNotes: QuestionNotes[];
  autofill: boolean;
  showConfetti: boolean;
  /** A list of headers that need to be de-duped by the extension when pasting into general notes */
  replaceHeaders: string[];
}

export interface FillScorecardResponse {
  /**
   * The total number of scorecard / feedback form fields present on the page
   */
  numSections: number;
  /**
   * The number of scorecard / feedback form fields that were
   * able to be matched to call notes
   */
  numMatched: number;
  /**
   * The number of scorecard / feedback form fields that were not
   * able to be matched to call notes
   */
  numUnmatched: number;
  /**
   * The data sent to the extension
   */
  scorecardData: FillScorecardPayload;
}

const INTERVIEWER_NOTES_HEADER = "Notes from interviewer:";
const DEFAULT_AI_NOTES_HEADER = "✨ AI summarized notes:";
const HIDE_AI_NOTES_HEADER = "Summarized notes:";

/**
 * This function takes all of the call's notes and formats them for the extension.
 * The format for a single question looks like this:
 *
 * ```
 * Notes from interviewer:
 *
 * • Interviewer marked question at 10:12
 * • Provided solid feedback 10:30
 *
 * ✨ AI summarized notes:
 *
 * Candidate discussed: work history 11:15
 * • Candidate previously worked at XYZ corp
 * • Left after changes in management
 *
 * Tell me about your last project 12:30
 * • Previously worked on an AI chatbot for customer service
 * • Reduced on-call load by 35%
 * ```
 *
 * For the scorecard template, we omit the AI headers since they will
 * always be the same as the item being pasted into on the ATS page, so:
 * ```
 * Notes from interviewer:
 *
 * • Interviewer marked question at 10:12
 * • Provided solid feedback 10:30
 *
 * ✨ AI summarized notes:
 *
 * • Candidate previously worked at XYZ corp
 * • Left after changes in management
 * • Previously worked on an AI chatbot for customer service
 * • Reduced on-call load by 35%
 * ```
 *
 */
export function getScorecardData({
  call,
  ats,
  autofill,
  showConfetti,
  latestFormatEnabled,
  hideAiHeaders,
  includeTimestamps,
}: FillScorecardParams): FillScorecardPayload {
  const isGreenhouse = ats === Ats.Greenhouse;
  const isLever = ats === Ats.Lever;
  const isAshby = ats === Ats.Ashby;

  invariant(
    isSupportedAts(ats),
    `"${ats}" is not a supported ATS for fill scorecard`
  );

  const AI_NOTES_HEADER = hideAiHeaders
    ? HIDE_AI_NOTES_HEADER
    : DEFAULT_AI_NOTES_HEADER;

  const isHtmlSupported = isGreenhouse || isAshby;
  const isAiScorecardTemplate =
    call?.aiNotesCustomFormat?.format === CallAiSummaryFormat.Scorecard;

  const callId = call?.id;
  const generalNotes = gatherNotes(call?.generalNotes);
  let questionNotes: QuestionNotes[];

  if (isV3Call(call)) {
    questionNotes = gatherScorecardNotes(call?.scorecard);
  } else {
    questionNotes = gatherQuestionNotes(call?.questions ?? []);
  }

  mergeAiScorecardQuestionNotes(questionNotes);
  handleLeverGeneralNotes();

  return {
    generalNotes,
    questionNotes,
    autofill,
    showConfetti,
    replaceHeaders: [INTERVIEWER_NOTES_HEADER, AI_NOTES_HEADER],
  };

  /**
   * Since Lever does not have a "General Notes" section, we put
   * general notes into the first fillable question, if available
   */
  function handleLeverGeneralNotes(): void {
    if (!isLever) return;
    const questions =
      (isV3Call(call) ? call?.scorecard?.questions : call.questions) ?? [];
    const firstPasteableIdx = questions.findIndex(
      (q) => q.leverFieldType === LeverFeedbackTemplateFieldTypes.Textarea
    );

    if (firstPasteableIdx !== -1) {
      const { notes: firstQuestionNotes } = questionNotes[firstPasteableIdx];
      const combineNotesParts: string[] = [];
      if (generalNotes.text) {
        combineNotesParts.push("General Notes:", generalNotes.text);
      }
      if (generalNotes.text && firstQuestionNotes.text) {
        combineNotesParts.push("---");
      }
      if (firstQuestionNotes.text) {
        combineNotesParts.push(firstQuestionNotes.text);
      }

      firstQuestionNotes.count += generalNotes.count;
      firstQuestionNotes.text = combineNotesParts.join("\n\n");
    }
  }

  function formatNote({
    parts,
    includeBullet = true,
    isAtsSingleLineInput,
  }: {
    parts: string[];
    isAtsSingleLineInput?: boolean;
    includeBullet?: boolean;
  }): string {
    const bullet = includeBullet && !isAtsSingleLineInput ? "•" : "";
    const separator = isHtmlSupported ? "&nbsp;&nbsp;" : "  ";

    return [bullet, ...parts].filter((s) => !!s).join(separator);
  }

  /**
   * Gathers manual call notes into an `ExtensionNote`
   */
  function gatherNotes(
    notes: NotePartsFragment[] | undefined,
    timestamp?: number | null,
    description?: string,
    isAtsSingleLineInput?: boolean
  ): ExtensionNote {
    const result: CueNotes = { description, cues: [], notes: [] };

    if (typeof timestamp === "number" && includeTimestamps) {
      result.cues.push(
        isHtmlSupported
          ? buildNoteTimestampLink({ time: timestamp, callId })
          : formatDuration(timestamp)
      );
    }

    (notes || []).forEach((note) => {
      const time = formatDuration(note.time);

      if (note.type === CallNoteType.Cue && includeTimestamps) {
        result.cues.push(isHtmlSupported ? buildNoteTimestampLink(note) : time);
      } else {
        const icon = iconForNote(note);
        const { text } = note;
        const parts = [icon, text];

        if (isHtmlSupported && includeTimestamps) {
          const link = buildNoteTimestampLink(note);
          parts.push(link);
        }

        result.notes.push(formatNote({ parts, isAtsSingleLineInput }));
      }
    });

    return buildExtensionNote(result, {
      isAtsSingleLineInput,
      header: INTERVIEWER_NOTES_HEADER,
    });
  }

  /**
   * Gathers AI notes into an `ExtensionNote`
   */
  function gatherAiNoteAnswerItems(
    notes: Pick<AiQuestionNoteAnswerItem, "id" | "currentText">[],
    questionHeader: string,
    startTime: number,
    isAtsSingleLineInput: boolean,
    callId?: string
  ): ExtensionNote {
    const result: CueNotes = {
      description: questionHeader,
      cues: [],
      notes: [],
    };

    const shouldAddHeader = notes.length > 0 && !isAiScorecardTemplate;
    if (shouldAddHeader) {
      /**
       * Add the summary header (e.g. "Candidate discussed: ...") for
       * non-scorecard templates
       */
      const summaryHeaderItems = latestFormatEnabled
        ? [questionHeader]
        : ["✨", questionHeader];

      if (callId && isHtmlSupported && includeTimestamps) {
        const link = buildCallTimestampLink(callId, startTime);
        summaryHeaderItems.push(link);
      }
      result.notes.push(
        formatNote({
          parts: summaryHeaderItems,
          includeBullet: false,
          isAtsSingleLineInput,
        })
      );
    }

    if (notes.length > 0) {
      notes.forEach((note) => {
        const text = note.currentText;
        result.notes.push(formatNote({ parts: [text], isAtsSingleLineInput }));
      });
    }

    const extensionNote = buildExtensionNote(result, {
      isAtsSingleLineInput,
      header: AI_NOTES_HEADER,
    });

    if (shouldAddHeader) {
      /** Decrement by 1 so the header (`summaryHeaderItems`) is not counted */
      extensionNote.count -= 1;
    }

    return extensionNote;
  }

  /**
   * Gathers (manual) guide item notes for an `iaVersion = 2` call
   */
  function gatherQuestionNotes(
    questions: NonNullable<NotesForScorecardQuery["call"]>["questions"]
  ): QuestionNotes[] {
    const result: QuestionNotes[] = questions.map((question) => {
      const questionNote: QuestionNotes = {
        id: question.id,
        notes: gatherNotes(
          question.questionNotes,
          null,
          question.description ?? undefined,
          question.isAtsSingleLineInput
        ),
        text: getQuestionText(question.description ?? ""),
      };
      return questionNote;
    });

    return result;
  }

  /**
   * Gathers (manual) guide item notes for an `iaVersion = 3` call
   */
  function gatherScorecardNotes(
    scorecard: NonNullable<NotesForScorecardV3Query["call"]>["scorecard"]
  ): QuestionNotes[] {
    const result: QuestionNotes[] =
      scorecard?.questions.map((question) => {
        const questionNote: QuestionNotes = {
          id: question.id,
          notes: gatherNotes(
            question.questionNotes,
            question.marked ? question.markedTime : undefined,
            question.itemText,
            question.isAtsSingleLineInput
          ),
          text: getQuestionText(question.itemText ?? ""),
        };
        return questionNote;
      }) ?? [];

    return result;
  }

  /**
   * Absorbs AI notes into the corresponding (manual) guide item notes
   */
  function mergeAiScorecardQuestionNotes(result: QuestionNotes[]): void {
    const resultMap = new Map(
      result.map((questionNotes) => [questionNotes.text, questionNotes])
    );
    const aiScorecardQuestionNotes = call?.aiScorecardQuestionNotes ?? [];
    aiScorecardQuestionNotes.forEach((note) => {
      const questionNote: QuestionNotes = {
        id: note.id,
        notes: gatherAiNoteAnswerItems(
          note.callAiNoteAnswerItems,
          note.aiQuestion ?? "",
          note.startTime,
          note.isAtsSingleLineInput,
          callId
        ),
        text: getQuestionText(note.scorecardQuestion ?? ""),
      };

      // merge with existing notes with the same Scorecard Question
      const currentQuestionNote = resultMap.get(questionNote.text);
      if (!currentQuestionNote) {
        resultMap.set(questionNote.text, questionNote);
        result.push(questionNote);
      } else {
        // Update in place current note
        currentQuestionNote.notes.count += questionNote.notes.count;
        if (currentQuestionNote.notes.text === "") {
          currentQuestionNote.notes.text = questionNote.notes.text;
        } else {
          currentQuestionNote.notes.text =
            currentQuestionNote.notes.text.concat(
              isHtmlSupported
                ? "<br><br>"
                : note.isAtsSingleLineInput
                ? " | "
                : "\n\n",
              questionNote.notes.text
            );
        }
        if (currentQuestionNote.notes.generalText === "") {
          currentQuestionNote.notes.generalText =
            questionNote.notes.generalText;
        } else {
          currentQuestionNote.notes.generalText =
            currentQuestionNote.notes.generalText.concat(
              isHtmlSupported
                ? "<br><br>"
                : note.isAtsSingleLineInput
                ? " | "
                : "\n\n",
              questionNote.notes.generalText
            );
        }
      }
    });
  }

  /**
   * Creates an `ExtensionNote` from the given `CueNotes`
   */
  function buildExtensionNote(
    { cues, notes, description }: CueNotes,
    params: {
      isAtsSingleLineInput?: boolean;
      header?: string;
    }
  ): ExtensionNote {
    const { isAtsSingleLineInput, header = "" } = params;
    const result: ExtensionNote = { count: 0, text: "", generalText: "" };
    if (cues.length === 0 && notes.length === 0) return result;

    const sep = isHtmlSupported ? "<br>" : isAtsSingleLineInput ? " | " : "\n";
    if (header && latestFormatEnabled) {
      result.text += `${header}${sep}${sep}`;
      result.generalText += `${header}${sep}${sep}`;
    }

    if (cues.length > 0 && isHtmlSupported) {
      const cueText = `${cues.join(", ")}`;
      result.text +=
        formatNote({
          parts: [`Interviewer marked question at ${cueText}`],
          isAtsSingleLineInput,
        }) + sep;

      if (description) {
        result.generalText += `"${description}" marked at ${cueText}<br>`;
      } else {
        result.generalText += `Interviewer marked question at ${cueText}<br>`;
      }

      result.count += cues.length;
    }

    if (notes.length > 0) {
      const noteText = notes.join(sep);
      result.text += noteText;
      result.generalText += noteText;
      result.count += notes.length;
    }

    return result;
  }
}
const dummyEl = document.createElement("span");
const getQuestionText = (description: string): string => {
  dummyEl.innerText = description;
  return dummyEl.innerText;
};

/**
 * Alert configs
 */

// Helper functions
const itemNameForAts = (ats: Ats): string =>
  ats === Ats.Greenhouse ? "scorecard" : "feedback form";
const extraNoteLocationForAts = (ats: Ats): string =>
  ats === Ats.Greenhouse
    ? "general notes section, if available"
    : "first available notes section";

/**
 * The alert to be shown when no matching call is found for the scorecard
 */
export const cantFindMatchingCallAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);
  return {
    type: "info",
    title: `To import BrightHire notes to this ${itemName}, find and select the corresponding interview.`,
    action: {
      displayText: "Open the BrightHire panel",
      actionName: "openDrawer",
    },
    afterAction: {
      title: `Select the interview that is associated with this ${itemName}.`,
      body: `Use the panel on the right.`,
    },
    body: `on the right and select the interview related to this ${itemName}.`,
  };
};

/**
 * The alert to be shown when the selected call is either transcribing or
 * generating AI notes
 */
export const notesLoadingAlertConfig = (hasNotes: boolean): AlertConfig => {
  return {
    type: "info",
    title: `Generating notes`,
    body: `BrightHire is processing your recording and generating AI notes${
      hasNotes
        ? `. Your manual notes from the Interview Assistant are available for you to review.`
        : ""
    }`,
  };
};

/**
 * The alert to be shown when the selected call matches 0 scorecard fields
 */
export const noFieldsMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "info",
    title: `BrightHire is unable to match the ${itemName} questions to the interview notes.`,
    body: `Pressing 'Import notes' will paste any notes to the ${extraNotesLocation}`,
  };
};

/**
 * The alert to be shown when the selected call matches some, but not all, scorecard fields
 */
export const someFieldsMatchAlertConfig = (
  ats: Ats,
  numMatched: number
): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "info",
    title: `BrightHire is able to match ${numMatched} ${itemName} question${
      numMatched === 1 ? "" : "s"
    } to available interview notes.`,
    body: `Pressing 'Import notes' will paste all notes to their matching
    ${itemName} questions and any unmatched notes will be pasted to the ${extraNotesLocation}.`,
  };
};

/**
 * The alert to be shown when the selected call matches all scorecard fields
 */
export const allFieldsMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);

  return {
    type: "success",
    title: `BrightHire can now import notes to your ${itemName}.`,
  };
};

/**
 * The alert to be shown when we have a match but the call has no notes
 */
export const allFieldsMatchButNoUserNotesAlertConfig = (
  ats: Ats
): AlertConfig => {
  const itemName = itemNameForAts(ats);

  return {
    type: "info",
    title: "There are no notes to import",
    body: `To have BrightHire complete your ${itemName} automatically,
    use the Interview Assistant to take notes during your interview.`,
  };
};

/**
 * The alert to be shown after pasting when no notes match
 */
export const postFillNoneMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "success",
    title: "All notes were imported successfully!",
    body: `All notes were pasted to the ${extraNotesLocation}.
    Always review your ${itemName} for accuracy before submitting it.`,
  };
};
/**
 * The alert to be shown after pasting a partial match of notes
 */
export const postFillPartialMatchAlertConfig = (
  ats: Ats,
  numSections: number,
  numMatched: number
): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "success",
    title: `We've filled out ${numMatched} of ${numSections} question${
      numSections === 1 ? "" : "s"
    } for you.`,
    body: `Any unmatched notes will be pasted to the ${extraNotesLocation}.
    Always review your ${itemName} for accuracy before submitting it.`,
  };
};

/**
 * The alert to be shown after pasting a full match of notes
 */
export const postFillFullMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);

  return {
    type: "success",
    title: `All notes were imported successfully!`,
    body: `Always review your ${itemName} for accuracy before submitting it.`,
  };
};

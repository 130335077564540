import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { FC } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";

import { Button, IconButton } from "../../../../components";
import DragHandleDots from "../../../../components/Icons/DragHandleDots";
import GuideItemLabel from "../../../pages/guide/GuideItems/GuideItemLabel";
import EditTemplateSubsection from "./EditTemplateSubsection";
import { TemplateSections } from "./types";

export type EditTemplateProps = {
  templateName: string;
  templateId: string;
  currentSections: TemplateSections;
  handleMoveSection: (result: DropResult) => void;
  handleMoveSubsection: (result: DropResult, sectionId: string) => void;
  handleTitleChange: (
    newTitle: string,
    sectionId: string,
    subsectionId?: string
  ) => void;
  handlePromptChange: (
    newPrompt: string,
    sectionId: string,
    subsectionId?: string
  ) => void;
  addSection: () => void;
  addSubsection: (sectionId: string) => void;
  deleteSection: (sectionId: string) => void;
  deleteSubsection: (sectionId: string, subsectionId: string) => void;
  isDefault: boolean;
  setIsDefault: (value: boolean) => void;
};

const EditTemplate: FC<EditTemplateProps> = ({
  templateName,
  templateId,
  currentSections,
  handleMoveSection,
  handleMoveSubsection,
  handleTitleChange,
  handlePromptChange,
  addSection,
  addSubsection,
  deleteSection,
  deleteSubsection,
  isDefault,
  setIsDefault,
}) => {
  return (
    <Box pb={3} mb={6} mx={6}>
      <GuideItemLabel text="Template name" />
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
        pb={8}
      >
        <Box>{templateName}</Box>
        <Box>
          <FormControl display="flex" width="auto" alignItems="center">
            <Switch
              size="sm"
              isChecked={isDefault}
              onChange={() => setIsDefault(!isDefault)}
            />
            <FormLabel color="gray.900" pl={1.5} mb={0} mr={0} fontSize="sm">
              Set as default template
            </FormLabel>
          </FormControl>
        </Box>
      </Flex>
      <Text color="gray.600" fontSize="sm" fontWeight="400">
        Topics & subtopics
      </Text>
      <DragDropContext
        onDragEnd={(result) => {
          handleMoveSection(result);
        }}
      >
        <Droppable droppableId={templateId}>
          {(provided) => (
            // eslint-disable-next-line @typescript-eslint/unbound-method
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {currentSections?.map((child, index) => (
                <Draggable
                  key={child.id}
                  draggableId={child.id}
                  index={index}
                  isDragDisabled={false}
                >
                  {(provided) => (
                    <Box
                      key={child.id}
                      backgroundColor="gray.50"
                      pt={4}
                      pb={5}
                      pr={4}
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="8px"
                      mt={5}
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Flex alignItems="flex-start">
                        <Flex
                          {...provided.dragHandleProps}
                          height={8}
                          width={8}
                          mr={1}
                          ml={2}
                          alignItems="center"
                          justifyContent="center"
                          data-testid={`drag-handle-${index}`}
                        >
                          <DragHandleDots width={5} height={5} />
                        </Flex>
                        <Flex direction="column" width="100%" gap={2}>
                          <Input
                            value={child.title}
                            placeholder="Topic grouping"
                            size="sm"
                            onChange={(event) => {
                              handleTitleChange(event.target.value, child.id);
                            }}
                          />
                        </Flex>
                        <IconButton
                          variant="icon"
                          aria-label="Delete Topic"
                          color="red.400"
                          backgroundColor="transparent"
                          icon={<HiOutlineTrash size={20} />}
                          ml={6}
                          onClick={() => deleteSection(child.id)}
                        />
                      </Flex>
                      <Box ml={10}>
                        <EditTemplateSubsection
                          subsections={child.subsections}
                          handleMoveSubsection={(result) => {
                            handleMoveSubsection(result, child.id);
                          }}
                          handleTitleChange={(newTitle, subsectionId) =>
                            handleTitleChange(newTitle, child.id, subsectionId)
                          }
                          handlePromptChange={(newPrompt, subsectionId) => {
                            handlePromptChange(
                              newPrompt,
                              child.id,
                              subsectionId
                            );
                          }}
                          addSubsection={() => addSubsection(child.id)}
                          deleteSubsection={(subsectionId) =>
                            deleteSubsection(child.id, subsectionId)
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        size="sm"
        mt={5}
        justifyContent="flex-start"
        variant="ghost"
        fontSize="sm"
        fontWeight="500"
        leftIcon={<HiOutlinePlus />}
        onClick={() => addSection()}
      >
        Add topic
      </Button>
    </Box>
  );
};

export default EditTemplate;

import { Box, Flex, Heading, Text, useTimeout } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import {
  createSearchParams,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  Button,
  CenteredCardLayout,
  Link,
  LoadingIndicator,
  useToast,
} from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import useInterviewAssistant from "../../../utils/popup";
import {
  useInterviewOptInQuery,
  useUpdateInterviewOptInMutation,
} from "../../graphql";

const httpLink = (location: string): string => {
  if (location.startsWith("http") === false) {
    return `https://${location}`;
  }
  return location;
};

const InterviewOptInPage: React.FC = () => {
  // https://meet.google.com/nyw-eyyx-ebt
  // https%3A%2F%2Fmeet.google.com%2Fnyw-eyyx-ebt
  // https://localhost:3000/launch/interview?location=https%3A%2F%2Fmeet.google.com%2Fnyw-eyyx-ebt
  usePageTracker("interview_opt_in");
  const [searchParams] = useSearchParams();
  const pathLocation = useLocation();
  const location = searchParams.get("location");
  const organization = searchParams.get("organization");
  const navigate = useNavigate();

  const nextPage = (path: string): void => {
    const p = createSearchParams({
      ...Object.fromEntries([...searchParams.entries()]),
    });
    navigate({
      pathname: path,
      search: `?${p.toString()}`,
    });
  };

  const { data } = useInterviewOptInQuery({
    variables: {
      location: location || "none",
      organizationId: organization || "none",
    },
    onCompleted: (optInData) => {
      // Needed for hard refreshes in the middle of the opt in flow
      if (optInData.interviewOptIn.errorMessage) {
        nextPage("/launch/interview/error");
      } else if (pathLocation.pathname.endsWith("yesno")) {
        nextPage("/launch/interview/yesno");
      } else if (pathLocation.pathname.endsWith("launch")) {
        nextPage("/launch/interview/launch");
      } else if (optInData.interviewOptIn.alwaysCandidate) {
        const p = createSearchParams({
          ...Object.fromEntries([...searchParams.entries()]),
          isInterviewer: "false",
        });
        navigate({
          pathname: "/launch/interview/yesno",
          search: `?${p.toString()}`,
        });
      } else {
        nextPage("/launch/interview/whois");
      }
    },
    onError: () => nextPage("/launch/interview/error"),
  });

  return (
    <CenteredCardLayout
      containerProps={{
        backgroundImage: "/static/images/opt_in_gradient_background.png",
        backgroundPosition: "top",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        alignItems: "center",
      }}
      cardProps={{ alignItems: "center" }}
    >
      {!data && <LoadingIndicator my={12} />}

      {/* Shows for no location and no organization as well */}
      {data && !location && (
        <StyledHeading>
          No video conference URL provided. Please reach out to your recruiting
          coordinator for help.
        </StyledHeading>
      )}
      {data && !organization && location && (
        <StyledHeading>
          Could not find interview. <br />
          Please click the link below to launch it directly.
        </StyledHeading>
      )}
      {data && organization && location && (
        <Routes>
          <Route
            path="/whois"
            element={
              <StepWhoIs
                whoIsText={data.interviewOptIn.whoIsPageText || ""}
                heroImageUrl={data.interviewOptIn.heroImageUrl || ""}
              />
            }
          />
          <Route
            path="/yesno"
            element={
              <StepYesNo
                location={location}
                organization={organization}
                yesNoText={data.interviewOptIn.yesNoPageText || ""}
                heroImageUrl={data.interviewOptIn.heroImageUrl || ""}
                learnMoreLink={data.interviewOptIn.learnMoreLink || ""}
              />
            }
          />
          <Route
            path="/launch"
            element={
              <StepLaunch
                location={location}
                heroImageUrl={data.interviewOptIn.heroImageUrl || ""}
              />
            }
          />
          <Route
            path="/error"
            element={
              <ErrorPage errorMessage={data?.interviewOptIn.errorMessage} />
            }
          />
        </Routes>
      )}
      {location && (
        <Box mt="4" color="gray.700">
          <Text maxWidth="600px" textAlign="center" fontSize="sm" m="auto">
            If you are experiencing issues, you can join your interview directly
            using this link:
            <br />
            <Link
              href={httpLink(location)}
              fontWeight="semibold"
              target="_blank"
            >
              {location}
            </Link>
          </Text>
        </Box>
      )}
    </CenteredCardLayout>
  );
};

const StyledHeading: typeof Heading = (props) => (
  <Heading
    size="md"
    fontWeight="semibold"
    lineHeight="7"
    my="6"
    maxW="600px"
    textAlign="center"
    color="gray.900"
    {...props}
  />
);

const ErrorPage: React.FC<{ errorMessage?: string | null }> = ({
  errorMessage,
}) => {
  return (
    <StyledHeading>
      {errorMessage ||
        "Something went wrong. Please try again or launch from the link below."}
    </StyledHeading>
  );
};

const StepWhoIs: React.FC<{
  whoIsText: string;
  heroImageUrl: string;
}> = ({ whoIsText, heroImageUrl }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const nextPage = (isInterviewer: boolean): void => {
    const p = createSearchParams({
      ...Object.fromEntries([...searchParams.entries()]),
      isInterviewer: isInterviewer.toString(),
    });
    navigate({
      pathname: "/launch/interview/yesno",
      search: `?${p.toString()}`,
    });
  };
  return (
    <>
      {heroImageUrl && (
        <img src={heroImageUrl} width="450px" style={{ marginTop: "2rem" }} />
      )}
      <StyledHeading>{whoIsText}</StyledHeading>
      <Flex
        mt="4"
        flexDir="row"
        flexWrap="wrap"
        columnGap="6"
        rowGap="3"
        justifyContent="center"
      >
        <Button
          variant="solid"
          onClick={() => nextPage(false)}
          textTransform="none"
        >
          Candidate
        </Button>
        <Button
          variant="solid"
          onClick={() => nextPage(true)}
          textTransform="none"
        >
          Interviewer
        </Button>
      </Flex>
    </>
  );
};

const StepYesNo: React.FC<{
  location: string;
  organization: string;
  yesNoText: string;
  heroImageUrl: string;
  learnMoreLink: string;
}> = ({ location, organization, yesNoText, heroImageUrl, learnMoreLink }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isInterviewer = searchParams.get("isInterviewer") === "true";

  const [updateInterviewOptIn, { loading }] = useUpdateInterviewOptInMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const nextPage = (shouldRecord: boolean): void => {
    const p = createSearchParams({
      ...Object.fromEntries([...searchParams.entries()]),
      shouldRecord: shouldRecord.toString(),
    });
    navigate({
      pathname: "/launch/interview/launch",
      search: `?${p.toString()}`,
    });
  };

  const optIn = (optIn: boolean): void => {
    updateInterviewOptIn({
      variables: {
        location,
        organizationId: organization,
        optIn,
        isInterviewer,
      },
      onCompleted: () => {
        nextPage(optIn);
      },
    });
  };

  return (
    <>
      {heroImageUrl && (
        <img src={heroImageUrl} width="450px" style={{ marginTop: "2rem" }} />
      )}
      <StyledHeading>{yesNoText}</StyledHeading>
      <Text maxWidth="700px" textAlign="center" mt="6">
        Our interviews are confidential and privacy is very important to us.{" "}
        <Link target="_blank" href={learnMoreLink}>
          Learn more.
        </Link>
      </Text>
      <Flex mt="10" flexDir="column" justifyContent="center">
        <Button
          variant="solid"
          isLoading={loading}
          onClick={() => optIn(true)}
          textTransform="none"
          aria-label="Agree to be recorded and take me to my interview"
          rightIcon={<FaArrowRight />}
          whiteSpace="normal"
          wordBreak="break-word"
          h="auto"
          minH="10"
          py="2"
        >
          <Box ml={4} mr={4} whiteSpace="normal" wordBreak="break-word">
            Agree to be recorded and take me to my interview
          </Box>
        </Button>
        <Button
          variant="outline"
          isLoading={loading}
          onClick={() => optIn(false)}
          mt="24px"
          textTransform="none"
          aria-label="Do not agree to be recorded and take me to my interview"
          rightIcon={<FaArrowRight />}
          whiteSpace="normal"
          wordBreak="break-word"
          h="auto"
          minH="10"
          py="2"
        >
          <Box ml={4} mr={4} whiteSpace="normal" wordBreak="break-word">
            Do not agree to be recorded and take me to my interview
          </Box>
        </Button>
      </Flex>
    </>
  );
};

const StepLaunch: React.FC<{ location: string; heroImageUrl: string }> = ({
  location,
  heroImageUrl,
}) => {
  const toast = useToast();
  const { openInterviewAssistant } = useInterviewAssistant();
  const [searchParams] = useSearchParams();
  const shouldRecord = searchParams.get("shouldRecord") === "true";
  const isInterviewer = searchParams.get("isInterviewer") === "true";

  useEffect(() => {
    if (shouldRecord) {
      toast({
        status: "success",
        title: "Success!",
        description: "Your interview will be recorded.",
      });
    } else {
      toast({
        status: "success",
        title: "Success!",
        description: "Your interview will NOT be recorded.",
      });
    }
  }, [shouldRecord]);
  useTimeout(() => {
    if (isInterviewer && shouldRecord) {
      openInterviewAssistant({
        path: `/interview-assistant/integrations/opt-in?location=${location}`,
      });
    } else {
      window.open(httpLink(location));
    }
  }, 1000);

  return (
    <>
      {heroImageUrl && (
        <img src={heroImageUrl} width="450px" style={{ marginTop: "2rem" }} />
      )}
      <StyledHeading>
        {shouldRecord
          ? "Great! Your interview will be recorded."
          : "You've successfully opted out. Your interview will not be recorded."}
      </StyledHeading>
      <Text maxWidth="700px" textAlign="center" mt="6">
        Please wait while we open your video interview or click the button below
        to open it.
      </Text>
      <Button
        variant="solid"
        onClick={() => window.open(httpLink(location))}
        textTransform="none"
        mt="6"
      >
        Launch interview
      </Button>
    </>
  );
};

InterviewOptInPage.displayName = "InterviewOptInPage";
export default InterviewOptInPage;

import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "../../../components";
import { DefaultLayout } from "../../layouts";
import { Message, TalkResponseContent } from "./types";
import usePlayQueue from "./usePlayQueue";
import useRecording from "./useRecording";
import useTalkResponse from "./useTalkResponse";

const AiRecruiter: React.FC = () => {
  const [conversation, setConversation] = useState<TalkResponseContent[]>([]);
  const { promptId } = useParams() as { promptId: string | undefined };
  usePlayQueue(
    conversation
      .map((c) => c.audio)
      .flat()
      .filter((a) => a) as string[]
  );
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const { recording, startRecording, stopRecording } = useRecording(
    (blob: Blob): void => {
      if (blob.size === 0) {
        return;
      }
      setAudioBlob(blob);
    }
  );
  // Convert conversation to an openai compatible message chain
  const messages: Message[] = conversation
    .map((talkResponse) => {
      const messages: Message[] = [
        {
          role: "user",
          content: talkResponse.transcription,
        },
        {
          role: "assistant",
          content: talkResponse.text_generation || "",
        },
      ];
      return messages;
    })
    .flat();
  const talkResponse = useTalkResponse(promptId, audioBlob, messages);
  // eslint-disable-next-line no-console
  console.info(
    "[AiRecruiter] numAudio:",
    talkResponse.content?.audio?.length || 0
  );

  // Collect talkResponses in conversation state
  useEffect(() => {
    const { content } = talkResponse;
    if (!content) {
      return;
    }
    // Initialize
    if (conversation.length === 0) {
      setConversation([content]);
      return;
    }

    const oldId = conversation[conversation.length - 1].id;
    const newId = content.id;
    const isNew = oldId !== newId;
    if (isNew) {
      // Append new entry
      setConversation((prev) => [...prev, content]);
    } else {
      // Update the last one
      setConversation((prev) => {
        const newConversation = [...prev];
        newConversation[newConversation.length - 1] = content;
        return newConversation;
      });
    }
  }, [talkResponse]);

  const startRecordingAndClearAudio = (): void => {
    setAudioBlob(null);
    startRecording();
  };

  return (
    <DefaultLayout>
      <Box margin="2rem 8rem">
        <Heading as="h1" size="lg" mb="1rem" mt="2rem">
          AI Phone Screen
        </Heading>
        {/* {blob && <AudioPlayer blob={blob} />} */}

        {conversation.map((c, i) => {
          return <TalkTurn key={c.id} content={c} />;
        })}

        <Box my={2} />

        {talkResponse.busy && <Button size="sm" disabled isLoading />}

        {!talkResponse.busy && recording && (
          <Button size="sm" onClick={stopRecording} variant="warning">
            Stop recording
          </Button>
        )}
        {!talkResponse.busy && !recording && (
          <Button size="sm" onClick={startRecordingAndClearAudio}>
            Start recording
          </Button>
        )}
      </Box>
    </DefaultLayout>
  );
};

const TalkTurn: React.FC<{ content: TalkResponseContent }> = ({ content }) => {
  return (
    <div>
      {content.transcription && (
        <Text my="0.5rem">
          <span>
            <b>Me</b>: {content.transcription}
          </span>
        </Text>
      )}
      {content.text_generation && (
        <Text my="0.5rem">
          <span>
            <b>Brighty</b>: {content.text_generation}
          </span>
        </Text>
      )}
    </div>
  );
};

export default AiRecruiter;

import { Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { BetaTag, Button } from "../../../../components";

type SkillsReportHeaderProps = {
  includeBuild?: boolean;
  includeDetails?: boolean;
};

const SkillsReportHeader: React.FC<SkillsReportHeaderProps> = ({
  includeBuild,
  includeDetails,
}) => {
  const navigate = useNavigate();
  const startBuildReport = (): void => navigate("/insights/skill-report");
  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      h={16}
      px={8}
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <HStack spacing={2} align="baseline">
        <Text fontSize="xl" fontWeight="500" color="gray.800">
          Skills Coverage
        </Text>
        <BetaTag bg="purple.300" _hover={{ bg: "purple.400" }} />
        {includeDetails && (
          <Button
            variant="link"
            fontSize="sm"
            fontWeight={500}
            // onClick={() => {}}
          >
            Details
          </Button>
        )}
      </HStack>
      {includeBuild && (
        <Flex>
          <Button onClick={startBuildReport} colorScheme="blue" size="sm">
            New report
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default SkillsReportHeader;

import { BoxProps, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiLogoZoom } from "react-icons/bi";
import { HiOutlinePhone, HiOutlineVideoCamera } from "react-icons/hi";
import { MdInfo } from "react-icons/md";
import { SiGooglemeet, SiMicrosoftteams } from "react-icons/si";

import {
  AddBrightHireToInterviewsMutation,
  InterviewAssistantEntryPoint,
  ScheduledInterviewListItemFragment,
  useAddBrightHireToInterviewsMutation,
} from "../../main/graphql";
import { getErrorContent } from "../../main/pages/integrations/getErrorContent";
import { useSendGAEvent } from "../../utils/googleAnalytics";
import useInterviewAssistant from "../../utils/popup";
import { LoadingIndicator, useToast } from "..";
import { Button } from "../Buttons";
import { ButtonAction, LaunchInterviewButton } from "./types";

interface LaunchButtonProps {
  scheduledInterview: ScheduledInterviewListItemFragment;
  listPosition?: number;
  showMarginLeft?: boolean;
  showUpdating?: "updating" | "updateFailed";
  interviewAssistantEntryPoint?: InterviewAssistantEntryPoint;
  onAddBrightHire?: (results: AddBrightHireToInterviewsMutation) => void;
  forceFullWidth?: boolean | undefined;
  boxProps?: BoxProps | undefined;
}

const ScheduledInterviewsLaunchButtonBeta: React.FC<LaunchButtonProps> = ({
  scheduledInterview,
  listPosition = 1,
  showMarginLeft = true,
  showUpdating = undefined,
  interviewAssistantEntryPoint,
  onAddBrightHire,
  forceFullWidth = false,
  boxProps = {
    mb: { base: 0, lg: 3 },
    justifyContent: "center",
    alignItems: "center",
  },
}) => {
  const toast = useToast();
  const { openInterviewAssistant } = useInterviewAssistant();
  const [isUpdating, setIsUpdating] = useState(false);

  const [zoomUrl, setZoomUrl] = useState<string>(
    scheduledInterview?.zoomMeetingUrl ?? scheduledInterview?.zoomJoinUrl ?? ""
  );

  const [googleMeetMeetingId, setGoogleMeetMeetingId] = useState<string>(
    scheduledInterview.googleMeetMeetings.length > 0
      ? scheduledInterview.googleMeetMeetings[0].id
      : ""
  );

  const [googleMeetMeetingUrl, setGoogleMeetMeetingUrl] = useState<string>(
    scheduledInterview?.googleMeetMeetingUrl ?? new Date().toISOString()
  );

  const [teamsNotetakerMeetingId, setTeamsNotetakerMeetingId] =
    useState<string>(
      scheduledInterview.teamsNotetakerMeetings.length > 0
        ? scheduledInterview.teamsNotetakerMeetings[0].id
        : ""
    );

  const [teamsNotetakerMeetingUrl, setTeamsNotetakerMeetingUrl] =
    useState<string>(
      scheduledInterview?.teamsMeetingUrl ?? new Date().toISOString()
    );

  /**
   * Set up the entire button in one go
   * @param state The action state of the button
   */
  const getButtonDetails = (
    state: ButtonAction,
    message?: string
  ): LaunchInterviewButton => {
    const btn: LaunchInterviewButton = {
      action: state,
    };

    // Add button tooltip content & function to execute for various states
    switch (state) {
      case ButtonAction.ADD:
        btn.tooltip = "Add BrightHire to this interview";
        btn.text = "Add BrightHire";
        btn.variant = "warning";
        btn.execute = () => {
          addBrightHireManually();
        };
        break;
      case ButtonAction.ERROR:
        btn.tooltip = message ?? "not found";
        btn.text = "Not available";
        btn.variant = "disabled";
        break;
      case ButtonAction.EXCLUDED:
        btn.tooltip = "BrightHire excluded";
        btn.text = "Not available";
        btn.variant = "disabled";
        break;
      case ButtonAction.GOOGLE_MEET:
        btn.tooltip = "Launch your interview using Google Meet";
        btn.text = "Start";
        btn.variant = listPosition === 1 ? "solid" : "outline";
        btn.leftIcon = <SiGooglemeet size={20} />;
        btn.execute = () =>
          openInterviewAssistant({
            path: "/interview-assistant/integrations/googleMeet",
            googleMeetMeetingId,
            googleMeetMeetingUrl,
            entryPoint: interviewAssistantEntryPoint ?? "",
          });
        break;
      case ButtonAction.TEAMS:
        btn.tooltip = "Launch your interview using Microsoft Teams";
        btn.text = "Start";
        btn.leftIcon = <SiMicrosoftteams size={20} />;
        btn.variant = listPosition === 1 ? "solid" : "outline";
        btn.execute = () =>
          openInterviewAssistant({
            path: "/interview-assistant/integrations/teams",
            teamsNotetakerMeetingId,
            teamsNotetakerMeetingUrl,
            entryPoint: interviewAssistantEntryPoint ?? "",
          });
        break;
      case ButtonAction.PHONE:
        btn.tooltip =
          "Launch your interview using BrightHire's phone integration";
        btn.text = "Start";
        btn.variant = listPosition === 1 ? "solid" : "outline";
        btn.leftIcon = <HiOutlinePhone size={20} />;
        btn.execute = () => {
          openInterviewAssistant({
            phoneNumber:
              scheduledInterview?.phoneNumber ||
              scheduledInterview?.candidate?.defaultPhoneNumber,
            candidateFirstName: scheduledInterview?.candidate?.firstName,
            candidateLastName: scheduledInterview?.candidate?.lastName,
            candidateId: scheduledInterview?.candidate?.id,
            positionId: scheduledInterview?.position?.id,
            callGuideId: scheduledInterview.callGuide?.id,
            greenhouseScorecardLink: scheduledInterview.greenhouseLink,
            leverInterviewLink: scheduledInterview.leverInterviewId
              ? `https://hire.lever.co/interviews/${scheduledInterview.leverInterviewId}`
              : null,
            scheduledInterviewId: scheduledInterview.id,
            smartrecruitersInterviewLink:
              scheduledInterview.smartrecruitersInterviewLink,
            name: scheduledInterview.name,
          });
        };
        break;
      case ButtonAction.UPDATING:
        btn.text = "Updating";
        btn.variant = "disabled";
        btn.tooltip =
          "We are completing some final checks on this interview. Please refresh the page in a few minutes to verify.";
        break;
      case ButtonAction.UPDATE_FAILED:
        btn.text = "Update failed";
        btn.variant = "disabled";
        btn.tooltip =
          "Unfortunately, we were not able to add BrightHire to this interview. Please reach out for assistance.";
        break;
      case ButtonAction.ZOOM:
        btn.text = "Launch Zoom";
        btn.tooltip = "Launch your interview using Zoom";
        btn.variant = listPosition === 1 ? "solid" : "outline";
        btn.leftIcon = <BiLogoZoom size={20} />;
        btn.execute = () =>
          openInterviewAssistant({
            path: "/interview-assistant/integrations/zoom",
            joinUrl: scheduledInterview.zoomMeetingUrl ?? zoomUrl,
            entryPoint: interviewAssistantEntryPoint ?? "",
          });
        break;
      case ButtonAction.OPT_IN:
        btn.text = "Start";
        if (scheduledInterview.zoomId) {
          btn.tooltip = "Launch your interview using Zoom";
          btn.leftIcon = <HiOutlineVideoCamera size={20} />;
        } else if (scheduledInterview.googleMeetMeetingUrl) {
          btn.tooltip = "Launch your interview using Google Meet";
          btn.leftIcon = <SiGooglemeet size={20} />;
        } else if (scheduledInterview.teamsMeetingUrl) {
          btn.tooltip = "Launch your interview using Microsoft Teams";
          btn.leftIcon = <SiMicrosoftteams size={20} />;
        }
        btn.execute = () =>
          window.open(
            scheduledInterview?.optInUrl ||
              "https://app.brighthire.ai/launch/interview"
          );
        break;
    }
    return btn;
  };

  const [button, setButton] = useState<LaunchInterviewButton>(
    getButtonDetails(ButtonAction.PHONE)
  );
  const sendGAEvent = useSendGAEvent();

  /**
   * Kick off import if the scheduled interview has a zoom ID set already from
   * among its attached meetings. If it doesn't we shouldn't get here anyway but
   * error fallback included just in case
   */
  const addBrightHireManually = (): void => {
    setButton({ action: ButtonAction.LOADING });
    if (
      scheduledInterview?.zoomId ||
      scheduledInterview?.googleMeetMeetingUrl ||
      scheduledInterview?.teamsMeetingUrl
    ) {
      setIsUpdating(true);
      sendGAEvent("add_bh", "calling", "single", scheduledInterview?.id);
      addBrightHire({
        variables: {
          scheduledInterviewIds: [scheduledInterview?.id],
          source: "listitem",
        },
      });
    } else {
      const message = getErrorContent("Meeting not found");
      setButtonDetails(ButtonAction.ERROR);
      toast({
        title: "Error",
        description: `${message.primary} ${message.secondary}`,
        status: "error",
      });
    }
  };

  /**
   * Get the button state given an action type
   * @param state The action state of the button
   */
  const setButtonDetails = (state: ButtonAction, message?: string): void => {
    const btn = getButtonDetails(state, message);
    setButton(btn);
  };

  // Import video meeting
  const [addBrightHire] = useAddBrightHireToInterviewsMutation({
    // Set the join URL for the button to launch the IA with it
    // If meeting was not successfully imported, explain why
    onCompleted: (meetingData) => {
      const results = meetingData?.addBrightHireToInterviews;
      const interviews = results?.scheduledInterviews;
      const errors = results?.errors;
      const interview = interviews?.length
        ? (interviews.find((i) => {
            return i.id === scheduledInterview.id;
          }) as ScheduledInterviewListItemFragment)
        : undefined;
      if (onAddBrightHire) onAddBrightHire(meetingData);
      if (interview) {
        handleButtonSetupForVideoMeetings(interview);
      } else {
        const err = errors?.length
          ? errors[0].message ?? "Something went wrong"
          : "Something went wrong";
        handleButtonSetupForVideoMeetings(undefined, err);
      }
    },
    // Set error state
    onError: (err) => {
      const message = getErrorContent(err.message);
      setButtonDetails(ButtonAction.ERROR, message.secondary);
    },
  });

  /**
   * Set up the button following an update operation
   * @param interview The scheduled interview affected
   */
  const handleButtonSetupForVideoMeetings = (
    interview?: ScheduledInterviewListItemFragment,
    message?: string
  ): void => {
    // If we have an interview but no identified video meeting
    if (interview && !interview?.isVideo) {
      return;
    }

    // Set up some deets
    let action: ButtonAction | undefined;
    let errorMessage: string | undefined;
    let status = showUpdating;

    // If the interview is set to import, don't show updating status anymore
    if (interview?.isImported) {
      setIsUpdating(false);
      status = undefined;
    }

    // Find out what state the button should be in
    if (status && status === "updateFailed") {
      action = ButtonAction.UPDATE_FAILED;
    } else if (status && status === "updating") {
      action = ButtonAction.UPDATING;
    } else if (message) {
      errorMessage = message;
      action = ButtonAction.ERROR;
    } else if (!interview?.isExcluded && !interview?.isImported && isUpdating) {
      action = ButtonAction.UPDATING;
    } else if (
      !interview?.isExcluded &&
      !interview?.isImported &&
      !interview?.canAddBrighthire
    ) {
      action = ButtonAction.ERROR;
      errorMessage = interview?.canAddBrighthireReason ?? "not found";
    } else if (!interview?.isExcluded && !interview?.isImported) {
      action = ButtonAction.ADD;
    } else if (interview?.isExcluded) {
      action = ButtonAction.EXCLUDED;
    } else if (interview?.optInUrl) {
      action = ButtonAction.OPT_IN;
    } else if (interview?.isImported && interview?.zoomId) {
      action = ButtonAction.ZOOM;
    } else if (interview?.isImported && interview?.googleMeetMeetingUrl) {
      action = ButtonAction.GOOGLE_MEET;
    } else if (interview?.isImported && interview?.teamsMeetingUrl) {
      action = ButtonAction.TEAMS;
    }

    // Set the button state
    if (action) {
      if (action === ButtonAction.ZOOM) {
        setZoomUrl(interview?.zoomMeetingUrl ?? interview?.zoomJoinUrl ?? "");
      } else if (action === ButtonAction.GOOGLE_MEET) {
        setGoogleMeetMeetingId(
          interview?.googleMeetMeetings?.length ?? -1 > 0
            ? interview?.googleMeetMeetings[0].id ?? ""
            : ""
        );
        setGoogleMeetMeetingUrl(interview?.googleMeetMeetingUrl ?? "");
      } else if (action === ButtonAction.TEAMS) {
        setTeamsNotetakerMeetingId(
          interview?.teamsNotetakerMeetings?.length ?? -1 > 0
            ? interview?.teamsNotetakerMeetings[0].id ?? ""
            : ""
        );
        setTeamsNotetakerMeetingUrl(interview?.teamsMeetingUrl ?? "");
      }
      setButtonDetails(action, errorMessage);
    }
  };

  /** INITAL BUTTON SETUP and further updates. When the interview gets updated,
   * update the button too
   */
  useEffect(() => {
    // Phone calls are the initial state of the button
    handleButtonSetupForVideoMeetings(scheduledInterview);
  }, [scheduledInterview, showUpdating]);

  /**
   * Reason meeting "can't" be launched. Shown on hover over an info icon if
   * the button is enabled anyway.
   */
  const reason = scheduledInterview?.canLaunchMeetingReason;

  return (
    <Flex
      mr={showMarginLeft && button.action !== ButtonAction.NONE ? 2 : 0}
      width="100%"
      {...boxProps}
    >
      {![ButtonAction.LOADING, ButtonAction.NONE].includes(button.action) && (
        <>
          {reason && (
            <Tooltip
              id={`scheduled-${
                scheduledInterview?.candidate?.id ?? "unknown"
              }-launch-info`}
              label={getErrorContent(reason).secondary}
            >
              <Icon as={MdInfo} mr={3} color="gray.400" />
            </Tooltip>
          )}
          <Tooltip
            id={`scheduled-${
              scheduledInterview?.candidate?.id ?? "unknown"
            }-launch`}
            placement="auto"
            label={button.tooltip}
          >
            <Button
              height={forceFullWidth ? 10 : 8}
              size={button.variant === "disabled" ? "xs" : "md"}
              isDisabled={button.variant === "disabled"}
              variant={button.variant}
              onClick={button.execute}
              data-testid={`launch-zoom-button-${listPosition}`}
              data-zoom-url={button.action === ButtonAction.ZOOM ? zoomUrl : ""}
              leftIcon={button.leftIcon}
              width={forceFullWidth ? "100%" : "auto"}
              borderRadius={forceFullWidth ? "8" : "6"}
            >
              {button.text}
            </Button>
          </Tooltip>
        </>
      )}
      {button.action === ButtonAction.LOADING && <LoadingIndicator size="xs" />}
    </Flex>
  );
};

export default ScheduledInterviewsLaunchButtonBeta;

import { Select } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSkillReportsQuery } from "../../../graphql";

const SkillsReportSelect: React.FC = () => {
  const navigate = useNavigate();
  const { reportId } = useParams() as { reportId: string };
  const { error, data } = useSkillReportsQuery();
  if (error) return null;
  return (
    <Select
      width="400px"
      fontSize="sm"
      color="black.900"
      onChange={(e) => navigate(`/insights/skill-report/${e.target.value}`)}
      value={reportId}
    >
      {data?.currentUser?.organization.skillReports.map((report) => (
        <option key={report.id} value={report.id}>
          {report.title}
        </option>
      ))}
    </Select>
  );
};

export default SkillsReportSelect;

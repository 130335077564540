import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineDuplicate,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { MdLink } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";

import { IconButton, LoadingIndicator } from "../../../../components";
import { copy } from "../../../../utils/clipboard";
import { formatDatetime } from "../../../../utils/datetime";
import { canViewAdvancedInsights } from "../../../../utils/permissions";
import {
  SkillsReportWithResultsFragment,
  useDuplicateSkillReportMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import AnalyticsSkillsReportTable from "./AnalyticsSkillsReportTable";
import DeleteSkillsReportModal from "./DeleteSkillsReportModal";
import EditSkillsReportModal from "./EditSkillsReportModal";
import SkillsReportHeader from "./SkillsReportHeader";
import SkillsReportSelect from "./SkillsReportSelect";

type AnalyticsSkillsReportResultsProps = {
  skillReport: SkillsReportWithResultsFragment;
  generating: boolean;
};

const AnalyticsSkillsReportResults: React.FC<
  AnalyticsSkillsReportResultsProps
> = ({ skillReport: report, generating }) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const [duplicateReport] = useDuplicateSkillReportMutation({
    onCompleted: (data) => {
      toast({
        title: "Report duplicated",
        status: "success",
      });
      const newReportId = data.duplicateSkillReport?.skillReport?.id;
      if (newReportId) {
        navigate(`/insights/skill-report/${newReportId}`);
      }
    },
    onError: () => {
      toast({
        title: "Error duplicating report",
        status: "error",
      });
    },
  });

  if (!canViewAdvancedInsights(currentUser)) {
    return <Navigate to="/" replace />;
  }

  const displayDateRangeValue = report
    ? `${formatDatetime(report.dateRangeStart, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })} - ${formatDatetime(report.dateRangeEnd, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`
    : " ";

  const reportCandidates = report?.results
    ? report.results.map((candidate) => ({
        ...candidate,
        skills: candidate.coverage
          ? JSON.parse(candidate.coverage).coverage
          : [],
      }))
    : [];

  const skillNamesList = report?.skills.map((s) => s.name) || [];

  const tableData = reportCandidates.map((candidate) => {
    const skills = skillNamesList.map((skillName) => {
      const skill = candidate.skills.find(
        (s: { name: string; covered: boolean }) => s.name === skillName
      );
      return {
        name: skillName,
        covered: skill?.covered || false,
      };
    });

    return {
      id: candidate.candidateId,
      candidateName: candidate.candidateName,
      numInterviews: candidate.numCalls,
      skills,
    };
  });

  const candidateCount = reportCandidates.length;
  const totalInterviewCount = reportCandidates.reduce(
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    (acc, candidate) => acc + candidate.numCalls,
    0
  );

  const reportPosition =
    report?.positions && report.positions.length > 0
      ? report.positions[0]
      : null;
  const positionDisplayTitle =
    reportPosition?.displayTitle || "No position title";
  const subHeader = `${candidateCount} candidates • ${totalInterviewCount} interviews • ${positionDisplayTitle}`;

  const chartCounts: AnalyticsBarChartData = skillNamesList
    .map((skillName, i) => {
      const count: number = reportCandidates.filter((candidate) =>
        candidate.skills.find(
          (s: { name: string; covered: boolean }) =>
            s.name === skillName && s.covered
        )
      ).length;

      return {
        key: `skill-${i}-${skillName}`,
        label: skillName,
        value: (count / candidateCount) * 100,
      };
    })
    .sort((a, b) => b.value - a.value);

  const canEditReport = report?.creatorId === currentUser?.id;
  return (
    <Box pb="100px">
      <DeleteSkillsReportModal
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
        reportId={report?.id}
      />
      <EditSkillsReportModal
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
        report={report}
      />
      <SkillsReportHeader includeBuild includeDetails />
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        px={8}
        py={3}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Flex alignItems="center">
          <SkillsReportSelect />
          {canEditReport && (
            <Tooltip label="Edit report" placement="bottom-start">
              <IconButton
                color="gray.900"
                aria-label="Edit report"
                variant="icon"
                icon={<HiOutlinePencil size="20px" />}
                disabled={generating || !report}
                ml={2}
                onClick={onOpenEditModal}
              />
            </Tooltip>
          )}
        </Flex>
        <Flex>
          <ButtonGroup spacing={2}>
            <Tooltip label="Share report" placement="bottom-start">
              <IconButton
                color="gray.900"
                aria-label="Share report"
                variant="icon"
                icon={<MdLink size="20px" />}
                disabled={generating || !report}
                onClick={() => {
                  copy(window.location.href);
                  toast({
                    title: "Report link copied to clipboard",
                    status: "success",
                  });
                }}
              />
            </Tooltip>
            <Tooltip label="Duplicate report" placement="bottom-start">
              <IconButton
                color="gray.900"
                aria-label="Duplicate report"
                variant="icon"
                disabled={generating || !report}
                icon={<HiOutlineDuplicate size="20px" />}
                onClick={() => {
                  duplicateReport({
                    variables: {
                      reportId: report?.id,
                    },
                  });
                }}
              />
            </Tooltip>
            <Tooltip label="Delete report" placement="bottom-start">
              <IconButton
                color="red.500"
                aria-label="Delete report"
                variant="icon"
                disabled={generating || !report}
                icon={<HiOutlineTrash size="20px" />}
                onClick={onOpenDeleteModal}
              />
            </Tooltip>
          </ButtonGroup>
        </Flex>
      </Flex>
      <Flex flexDir="column" p={8} pt={12}>
        <VStack spacing={6}>
          <Flex direction="column" alignSelf="start">
            <Text fontSize="3xl" fontWeight={400}>
              How consistently are key interview topics being covered?
            </Text>
            <Text fontWeight={400} color="gray.400">
              Identify which topics are discussed during interviews across all
              candidates for one or more positions.
            </Text>
          </Flex>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date={displayDateRangeValue}
              subheader={subHeader}
            />
            <Box w="100%" py="6">
              {generating && <LoadingIndicator />}
              {!generating && tableData.length === 0 && (
                <Center>
                  <Text fontSize="sm" color="gray.500">
                    No data available for this position and date range. Try
                    generating a new report with different settings.
                  </Text>
                </Center>
              )}
              {!generating && tableData.length > 0 && (
                <AnalyticsBarChart
                  data={chartCounts}
                  margin={{ left: 180 }}
                  multiColor
                />
              )}
            </Box>
          </ReportSection>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date={displayDateRangeValue}
              subheader={subHeader}
            />
            <Box mt="3" width="100%">
              {generating && <AnalyticsReportTableSkeleton />}
              {!generating && tableData.length === 0 && (
                <Center py="6">
                  <Text fontSize="sm" color="gray.500">
                    No data available for this position and date range. Try
                    generating a new report with different settings.
                  </Text>
                </Center>
              )}
              {!generating && tableData.length > 0 && (
                <AnalyticsSkillsReportTable
                  tableData={tableData}
                  skills={skillNamesList}
                />
              )}
            </Box>
          </ReportSection>
        </VStack>
      </Flex>
    </Box>
  );
};

const ReportSection: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <VStack
      spacing={6}
      w="100%"
      p={6}
      pt={5}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="xl"
    >
      {children}
    </VStack>
  );
};

const ReportSectionHeader: React.FC<{
  header: string;
  date: string;
  subheader: string;
}> = ({ header, date, subheader }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%" direction="row" align="center" justify="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {header}
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.400" ml={2}>
          {date}
        </Text>
      </Flex>
      <Text fontSize="sm" fontWeight={400} color="gray.600">
        {subheader}
      </Text>
    </Flex>
  );
};

export default AnalyticsSkillsReportResults;

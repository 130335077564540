import { Box, Flex } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useToast } from "../../../../../components";
import ProgressStepper from "../../../../../components/ProgressStepper/ProgressStepper";
import { daysAgo, formatISODate } from "../../../../../utils/datetime";
import { usePageTracker } from "../../../../../utils/googleAnalytics";
import { canViewAdvancedInsights } from "../../../../../utils/permissions";
import {
  FilterValue,
  SkillReportSkillsSource,
  useCreateSkillReportMutation,
  useRunSkillReportMutation,
} from "../../../../graphql";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import SkillsReportHeader from "../SkillsReportHeader";
import SkillsReportBuilderStepOne from "./SkillsReportBuilderStepOne";
import SkillsReportBuilderStepTwo, {
  SkillsReportBuilderStepTwoFormData,
} from "./SkillsReportBuilderStepTwo";
import { PositionOptions } from "./SkillsReportPositionSelect";
import { DateRangeState } from "./types";

interface GenerateReportData {
  positionIds: string[];
  candidateFilter?: string; // TODO: Remove this?
  dateRange: {
    start: Date;
    end: Date;
  };
  source: SkillReportSkillsSource;
  skills: Array<{
    name: string;
    description?: string;
  }>;
}

const default90Days: DateRangeState = {
  start: daysAgo(90),
  end: new Date(),
};

const AnalyticsSkillsReportBuilder: React.FC = () => {
  usePageTracker("skill_report_builder");
  const navigate = useNavigate();
  const toast = useToast();

  const currentUser = useCurrentUser();
  const [builderStep, setBuilderStep] = useState(0);
  const [selectedPositions, setSelectedPositions] = useState<PositionOptions>(
    []
  );
  const [candidateFilter, setCandidateFilter] = useState<string | undefined>();
  const [interviewStages, setInterviewStages] = useState<FilterValue[]>([]);
  const [minInterviewCount, setMinInterviewCount] = useState<number>(1);
  const [dateRange, setDateRange] = useState(default90Days);
  const [title, setTitle] = useState<string>("");

  const positionIds = selectedPositions?.map((position) => position.id) || [];

  const [createSkillsReport] = useCreateSkillReportMutation();
  const [runSkillsReport] = useRunSkillReportMutation();
  const handleGenerateReport = useCallback(
    (data: GenerateReportData): void => {
      const interviewStageFilters = JSON.stringify(
        interviewStages.map((stage) => ({
          label: stage.label,
          value: stage.value,
        }))
      );
      createSkillsReport({
        variables: {
          positionIds: data.positionIds,
          dateRangeStart: formatISODate(data.dateRange.start),
          dateRangeEnd: formatISODate(data.dateRange.end),
          skills: data.skills.map(
            (skill: { name: string; description?: string }) => ({
              name: skill.name,
              description: skill.description,
            })
          ),
          interviewStageFilters,
          minimumInterviewCountFilter: minInterviewCount,
          source: data.source,
          title: title || "",
        },
        onCompleted: (data) => {
          const newReportId = data.createSkillReport?.skillReport.id;
          if (newReportId) {
            runSkillsReport({
              variables: {
                reportId: newReportId,
              },
            });
            navigate(`/insights/skill-report/${newReportId}`);
          }
        },
        onError: (error) => {
          toast({
            title: `Error`,
            description: error.message,
            status: "error",
          });
        },
      });
    },
    [
      createSkillsReport,
      runSkillsReport,
      interviewStages,
      minInterviewCount,
      title,
      toast,
      navigate,
    ]
  );

  const generateReport = useCallback(
    (data: SkillsReportBuilderStepTwoFormData): void => {
      const { source, skills } = data;
      if (!positionIds.length) {
        toast({
          title: "No positions selected",
          description:
            "Please select at least one position to generate the report.",
          status: "warning",
        });
        return;
      }
      handleGenerateReport({
        positionIds,
        candidateFilter,
        dateRange,
        source,
        skills: skills.filter(
          (skill) => skill.name && skill.name.trim() !== ""
        ),
      });
    },
    [positionIds, candidateFilter, dateRange, handleGenerateReport]
  );

  if (!canViewAdvancedInsights(currentUser)) {
    return <Navigate to="/" replace />;
  }

  return (
    <Flex
      flexDir="column"
      minH="100vh"
      bg="rgba(35, 152, 251, 0.03)"
      pb="100px"
    >
      <SkillsReportHeader />
      <Box py="8" margin="0 auto">
        <ProgressStepper
          steps={["Basics", "Skills", "Done"]}
          activeStepIndex={builderStep || 0}
          stepBgColor="#F5F9FD"
        />
      </Box>
      {builderStep === 0 && (
        <SkillsReportBuilderStepOne
          onCancel={() =>
            setBuilderStep(1)
          } /* TODO: figure out what clicking cancel does on the first screen */
          onComplete={() => setBuilderStep(1)}
          selectedPositions={selectedPositions}
          candidateFilter={candidateFilter}
          dateRange={dateRange}
          interviewStages={interviewStages}
          minInterviewCount={minInterviewCount}
          title={title}
          onSelectedPositionsChange={setSelectedPositions}
          onCandidateFilterChange={setCandidateFilter}
          onDateRangeChange={setDateRange}
          onInterviewStagesChange={setInterviewStages}
          onMinInterviewCountChange={setMinInterviewCount}
          onTitleChange={setTitle}
        />
      )}
      {builderStep === 1 && positionIds.length && (
        <SkillsReportBuilderStepTwo
          selectedPositions={selectedPositions}
          onCancel={() => setBuilderStep(0)}
          onComplete={(data) => generateReport(data)}
        />
      )}
    </Flex>
  );
};

export default AnalyticsSkillsReportBuilder;

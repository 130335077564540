/* eslint-disable camelcase */
import LogRocket from "logrocket";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import config from "../config";
import { checkCookieExistence } from "./cookie";
import { uuidSubStr } from "./regex";

export const SegmentAnalytics = {
  track: (
    event: string,
    properties?: Record<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void
  ): void => {
    const isImpersonating = checkCookieExistence("is_impersonating");
    if (!window.analytics || isImpersonating) return;

    analytics.track(event, properties, options, callback);
  },

  page: (
    name?: string,
    properties?: Record<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void
  ): void => {
    const isImpersonating = checkCookieExistence("is_impersonating");
    if (!window.analytics || isImpersonating) return;

    // Note: url, title, referrer and path are collected automatically
    analytics.page(name, properties, options, callback);
  },

  identify: (
    userId: string,
    traits?: Record<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void
  ): void => {
    const isImpersonating = checkCookieExistence("is_impersonating");
    if (!window.analytics || isImpersonating) return;

    analytics.identify(userId, traits, options, callback);
  },
};

export type CATEGORIES =
  | "analytics"
  | "auth"
  | "call_guides"
  | "call_no_answer"
  | "call_review"
  | "calling"
  | "candidate_compare"
  | "candidate"
  | "candidate_alert"
  | "candidates"
  | "job_description"
  | "home_page"
  | "in_person"
  | "interview_plan"
  | "text_before_call"
  | "keyword_search"
  | "on_boarding"
  | "positions"
  | "search"
  | "scoring"
  | "template_settings"
  | "user_feedback"
  | "user_interaction"
  | "user_notification_settings"
  | "user_settings";

const normalizePath = (path: string): string =>
  path.replace(RegExp(uuidSubStr, "g"), ":id");

export type GAEventDetails = {
  action: string;
  category?: CATEGORIES;
  label?: string;
  value?: string;
  params?: Record<string, unknown>;
};

export type SendGAEvent = (
  action: string,
  eventCategory?: CATEGORIES,
  eventLabel?: string,
  value?: string,
  params?: Record<string, unknown>
) => void;

export const useSendGAEvent = (): SendGAEvent => {
  const location = useLocation();
  return (
    action: string,
    eventCategory?: CATEGORIES,
    eventLabel?: string,
    value?: string,
    params?: Record<string, unknown>
  ): void => {
    const isImpersonating = checkCookieExistence("is_impersonating");
    if (
      config.appEnv === "test" ||
      // eslint-disable-next-line no-underscore-dangle
      window.__STORYBOOK_CLIENT_API__ ||
      isImpersonating
    ) {
      return;
    }
    // it's suggested to use the default GA events (action) when possible
    // https://developers.google.com/gtagjs/reference/event
    const path = normalizePath(location.pathname);
    gtag("event", action, {
      event_category: eventCategory,
      event_label: eventLabel,
      value,
      page_path: path,
      ...params,
    });

    SegmentAnalytics.track("ga_event", {
      event_action: action,
      event_category: eventCategory,
      event_label: eventLabel,
      value,
      page_path: path,
      ...params,
    });

    const logRocketName = ["event", action, eventCategory]
      .filter((s) => !!s)
      .join(":");
    LogRocket.track(logRocketName);
  };
};

export const usePageTracker = (title: string): void => {
  const location = useLocation();
  const isImpersonating = checkCookieExistence("is_impersonating");
  useEffect(() => {
    if (config.appEnv === "test" || isImpersonating) {
      return;
    }
    const path = normalizePath(location.pathname);
    gtag("event", "page_view", {
      page_title: title,
      page_location: window.location.href,
      page_path: path,
    });
    LogRocket.track(`page:${title}`);

    SegmentAnalytics.page(title);
  }, [isImpersonating, location.pathname]);
};

export const useDebouncedPageTracker = (): void => {
  const { pathname } = useLocation();
  const isImpersonating = checkCookieExistence("is_impersonating");
  useEffect(() => {
    if (config.appEnv === "test" || isImpersonating) return;

    const path = normalizePath(pathname);
    const timeout = setTimeout(() => {
      gtag("event", "page_view", {
        page_title: "global_app_tracking",
        page_location: window.location.href,
        page_path: path,
      });
      LogRocket.track(`page:${path}`);
      SegmentAnalytics.page(path);
    }, 650);

    return () => clearTimeout(timeout);
  }, [isImpersonating, pathname]);
};

interface CurrentUser {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  userRole?: {
    id: string;
  } | null;
}

export const identifyGoogleAnalytics = (currentUser: CurrentUser): void => {
  gtag("set", { user_id: currentUser.id });
  gtag("set", {
    // eslint-disable-next-line camelcase
    is_brighthire_user: currentUser.organization.name === "BrightHire",
  });
  SegmentAnalytics.identify(currentUser.id, {
    organization_id: currentUser.organization.id,
    organization_name: currentUser.organization.name,
    user_role_id: currentUser?.userRole?.id,
  });
};

import { useDisclosure } from "@chakra-ui/react";
import React from "react";

import { Button, ConfirmModal, useToast } from "../../../components";
import { useSetClientMembershipsMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface RemoveUserFromHiringTeamGroupButtonProps {
  user: { id: string };
  client: { id: string };
}

const RemoveUserFromHiringTeamGroupButton: React.FC<
  RemoveUserFromHiringTeamGroupButtonProps
> = ({ user, client }) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [setClientMemberships] = useSetClientMembershipsMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted: (data) => {
      if (data.setClientMemberships) {
        toast({
          title: "Success",
          description: "Removed from hiring yeam",
          status: "success",
        });
      }
    },
    refetchQueries: ["OrgHiringTeamGroup"],
  });

  const onSubmit = (): void => {
    setClientMemberships({
      variables: {
        users: [
          {
            userId: user.id,
            clientId: client.id,
            positionRoleId: null,
          },
        ],
      },
    });
    onClose();
  };

  const enabled =
    currentUser.userRole?.canManageHiringTeam || user.id !== currentUser.id;

  return (
    <>
      <Button
        size="xs"
        variant="dangerOutline"
        onClick={onOpen}
        disabled={!enabled}
      >
        Remove
      </Button>
      <ConfirmModal
        modalBodyText="Are you sure you want to remove this user from the hiring team?"
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={onSubmit}
      />
    </>
  );
};

export default RemoveUserFromHiringTeamGroupButton;
